import { styled } from "styled-components";
import { 
    StyledDiv,
    StyledFlex,
    StyledFlexCenterColumn,
    StyledFlexColumn,
    StyledGrid,
    StyledImg,
    StyledLI,
    StyledOL,
} from "./StyledCommon";

export const StyledServiceWrapper = styled(StyledFlexColumn)`
    flex-direction: column;
`;

export const StyledServiceTitle = styled(StyledDiv)`
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 1100px;
    align-self: center;
`;

export const StyledContentsWrapper = styled(StyledFlexColumn)`
    flex: 1 1 auto;
    backgruond: yellow;
`;

export const StyledSubTitleOL = styled(StyledOL)`
    padding-top: 40px;
    text-align: center;
    max-width: 1300px;
`;

export const StyledSubTitleLI = styled(StyledLI)`
    line-height: 2.5rem;
    text-align: left;
    padding-bottom: 10px;
    text-indent: 10px;
`;

export const StyledServiceDesc = styled(StyledDiv)`
    text-align: left;
    padding: 10px 20px;
`;

export const StyledServicesGrid = styled(StyledGrid)`
    grid-template-columns: auto auto;
    gap: 20px;
    padding-top: 30px;

    @media screen and (max-width: 1150px) {
        grid-template-columns: auto;
    }
`;

export const StyledServiceItemContainer = styled(StyledFlex)`
    padding: 5px;
`;

export const StyledServiceItemIconBg = styled(StyledFlexCenterColumn)`
    background-color: #610BEF;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 5px;
    margin: 5px 20px;
    
    @media screen and (max-width: 650px) {
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        margin-left: 0;
    }
`;

export const StyledServiceItemImg = styled(StyledImg)`
    width: 20px;
`;

export const StyledServiceItemName = styled(StyledDiv)`
    font-size: 18px;
    font-weight: 800;
`;

export const StyledServiceItemDescription = styled(StyledDiv)`
    font-size: 16px;
    font-weight: 400;
    padding-top: 5px;
    line-height: 30px;
`;