import React from 'react';
import { 
  StyledHomeActionArea,
  StyledHomeInput,
  StyledHomeWrapper,
  SyledHomeTrackBtn,
} from '../../styled-components/StyledHome';
import { StyledContainer, StyledWrapper } from '../../styled-components/StyledCommon';

const HomeComponent = () => {
  return (
    <StyledContainer id='home'>
        <StyledWrapper>
          <StyledHomeWrapper>
            <StyledHomeActionArea>
                <StyledHomeInput type='text' placeholder='Eg: 01GAZ99' />
                <SyledHomeTrackBtn>Tracking</SyledHomeTrackBtn>
            </StyledHomeActionArea>
          </StyledHomeWrapper>
        </StyledWrapper>
    </StyledContainer>
  )
}

export default HomeComponent;