import { styled } from "styled-components";
import { StyledDiv, StyledFlexCenter, StyledFlexCenterColumn } from "./StyledCommon";

export const StyledFooterWrapper = styled(StyledFlexCenterColumn)`
    flex: 1 1 auto;
`;

export const StyledFooterSocialWrapper = styled(StyledFlexCenter)`
    align-items: bottom;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
        padding: 10px;
        cursor: pointer;
        width: 40px;
    }

    @media screen and (max-width: 400px) {
        & > * {
            width: 25px;
        }
    }
`;

export const StyledFooterText = styled(StyledDiv)`
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.75px;
    text-align: left;
    color: #A0A3BD;
    padding-top: 14px;
`;
