import { styled } from 'styled-components';

export const StyledDiv = styled.div``;
export const StyledImg = styled.img``;
export const StyledP = styled.p``;
export const StyledOL = styled.ol``;
export const StyledLI = styled.li``;
export const StyledInput = styled.input`
    padding: 10px 20px;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    color: gray;
    :focus {
        outline: none;
    }
`;
export const StyledBtn = styled.button`
    border: none;
    border-radius: 10px;
`;
export const StyledTextArea = styled.textarea`
    padding: 10px 20px;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    color: gray;
    :focus {
        outline: none;
    }
`;

export const StyledFlex = styled(StyledDiv)`
    display: flex;
`;

export const StyledFlexColumn = styled(StyledFlex)`
    flex-direction: column;
`;

export const StyledGrid = styled(StyledDiv)`
    display: grid;
`;


export const StyledFlexCenter = styled(StyledFlex)`
    justify-content: center;
    align-items: center;
`;

export const StyledFlexCenterColumn = styled(StyledFlexCenter)`
    flex-direction: column;
`;

export const StyledContainer = styled(StyledFlexCenter)`
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const StyledWrapper = styled(StyledFlex)`
    width: 100%;
    max-width: 1500px;
    padding: 0 100px;
    position: relative;

    @media screen and (max-width: 760px) {
        padding: 0 60px;
    }

    @media screen and (max-width: 500px) {
        padding: 0 30px;
    }
`;
