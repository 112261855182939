import React from 'react';
import AboutSvg from '../../assets/svgs/about-img.svg';
import BlobSvg from '../../assets/svgs/blob.svg';
import MessageSvg from '../../assets/svgs/message.svg';
import DiamondSvg from '../../assets/svgs/diamond.svg';
import { StyledContainer, StyledWrapper } from '../../styled-components/StyledCommon';
import { constants } from '../../common/constants';
import {
  AboutImg,
  BlobImg,
  StyledAboutLeft,
  StyledAboutParagraph,
  StyledAboutRight,
  StyledAboutWrapper,
  StyledDiamondImg,
  StyledMessageImg
} from '../../styled-components/StyledAbout';

const AboutComponent = () => {
  return (
    <StyledContainer id='about'>
      <StyledWrapper>
        <StyledAboutWrapper>
          <StyledAboutLeft>
            <BlobImg src={BlobSvg} alt='about-blob' />
            <AboutImg src={AboutSvg} alt='about' />
            <StyledMessageImg src={MessageSvg} alt='message-img' />
            <StyledDiamondImg src={DiamondSvg} alt='diamond-img' />
          </StyledAboutLeft>
          <StyledAboutRight>
            <StyledAboutParagraph dangerouslySetInnerHTML={{__html: [constants.aboutParagraph1]}}>
              {/* {constants.aboutParagraph1}
              <br />
              <br />
              {constants.aboutParagraph2} */}
            </StyledAboutParagraph>
          </StyledAboutRight>
        </StyledAboutWrapper>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default AboutComponent