import SettingIcon from '../assets/svgs/settings.svg';
import LightningBoltIcon from '../assets/svgs/lightning-bolt.svg';
import InsuranceIcon from '../assets/svgs/insurance.svg';
import LocationIcon from '../assets/svgs/location.svg';
import TruckIcon from '../assets/svgs/truck.svg';
import BullhornIcon from '../assets/svgs/bullhorn.svg';
import CashMoneyIcon from '../assets/svgs/cash-money.svg';
import CrossBorderIcon from '../assets/svgs/cross-border-icon.svg';
import AnnotationIcon from '../assets/svgs/annotation.svg';

export const routes = [
    {
        id: 'about',
        label: 'About',
    }, 
    {
        id: 'service',
        label: 'Service',
    }, 
    {
        id: 'contact',
        label: 'Contact',
    }
];

export const constants = {
    aboutParagraph1: 'Logistics is an important task of process optimization is to find innovative methods and approaches which enhance logistic structures for a better and more efficient fulfilments of customer needs. In this context, Neon Express born as a <b>tech-lead basic logistics</b> as the shipping and logistics industry relies heavily on technology, allowing businesses to plan, track and organize the shipments in a short time, increase productivity and efficiency and quick access to get information. With this approach various areas where a logistic system is operation can be embraced and the capabilities of our logistic system.<br/><b>Thailand</b> is the one of the IMT trilateral (India, Myanmar and Thailand); it is well suited to create new opportunities from the growth of the digital economy and e-commerce. Neon Express is ready to <b>collaborate</b> with our partner in digital way to expand our business together.',
    //aboutParagraph2: 'Thailand is the one of the IMT trilateral (India, Myanmar and Thailand); it is well suited to create new opportunities from the growth of the digital economy and e-commerce. Neon Express is ready to collaborate with our partner in digital way to expand our business together.',
    moto: 'We create more convenience services for comfortable lifestyles.',
    companyName: 'New Tara Company Limited',
    address: '36, Thanon Atsawa Phichet Road, Bang Ramat, Taling Chan, Bangkok, Thailand 10170.',
    footerText: '© 2023 POWER BY NEON EXPRESS',
}

export const serviceConstants = {
    title: `E-commerce leads the way in shipping and delivery growth. Neon Express helps businesses navigate all electronic channels of commerce.`,
    // subTitles: [
    //     `The biggest form being online shopping, also known as business to consumer(B2C). Here individual can order various products also pay for their purchase with online payment.`,
    //     `Business to Business(B2B), where companies, such as manufacturers and wholesalers or wholesalers and retailers exchange products and/or services.`,
    //     `Consumer to Consumer(C2C), is the exchange of products or services among consumer. Nowadays, we mostly use this term when referring to trading goods online.`,
    // ],
    description: `As us how Neon Express can help you grow your business via timely, cost-efficient logistics services with Smart Payment System.`,
    services: [{
        name: 'B2B Service',
        description: 'Customers who would like to send their products with professional and faster way, can connect with us. We are able to give you the best shipping solution.',
        icon: SettingIcon,
    },
    {
        name: 'B2C Service',
        description: 'If you find a strong logistics partners for your retails shop, online stores or E-commerce to target news client in a different market, we can be the one who serve you better with a special cost.',
        icon: SettingIcon,
    },
    {
        name: 'C2C Service',
        description: 'This is a business model that facilitate the transaction of products or services between customers. We provide the latest technology in logistics management and presenting you with practical solutions to your business requirements.',
        icon: SettingIcon,
    },
    {
        name: 'Last Mile Delivery',
        description: 'Open Neon Express Apps and submit your shipment. Some minutes away, you are able to deliver your items to your designated destination. Just chill and send your goods with us.',
        icon: LightningBoltIcon,
    },
    {
        name: 'Neon Point Partnership',
        description: 'How about you use your little space and make passive income? Join with us to make one of our Neon Point at your <b>region of residence</b>.',
        icon: AnnotationIcon,
    },
    {
        name: 'Cross Border Logistic',
        description: 'We provide cross-border (Land) transportation services for <b>commercial and individual</b> purposes from Thailand to neighbouring countries such as Myanmar, Malaysia and North-East India.',
        icon: CrossBorderIcon,
    },
    {
        name: 'Insurance',
        description: 'We guarantee your parcels if there is any incident against unforeseen event. We will <b>responsible</b> with the maximum 2,000 THB. Buying insurance could cover your valuable parcel for a lost, stolen or damaged shipment.',
        icon: InsuranceIcon,
    },
    {
        name: 'Drop-Off/Walk-in Service',
        description: 'Drop Off at our office or partner service point. Search for <b>Service Point</b> near you and drop off your parcels now.',
        icon: LocationIcon,
    },
    {
        name: 'Pickup Service',
        description: 'Register and book your pick up on Neon Express App now. Our <b>partner</b> service will come and pick up your parcels based on your selected date.',
        icon: TruckIcon,
    },
    {
        name: 'Fulfilment Support Service',
        description: 'We provide our logistics service to expand your fulfilment business, to attract more consumers across Myanmar and North-East India. You can connect via <b>API Integration</b> with us for your cost-effective shipping solution. We use innovative technology to incorporate our services into your business. Please Contact us to get more information.',
        icon: BullhornIcon,
    },
    {
        name: 'Easy Payment',
        description: 'Be Smart! Pay your shipping fees by <b>Neon Wallet</b>.',
        icon: CashMoneyIcon,
    },
        
    ]
}

