import React from 'react'
import { useState } from 'react';
import { 
    MapLocationImg, 
    StyledContactAddress, 
    StyledContactAddressWrapper, 
    StyledContactBg1, 
    StyledContactBg2, 
    StyledContactDetailsWrapper, 
    StyledContactForm, 
    StyledContactInput, 
    StyledContactSubmitBtn, 
    StyledContactTextArea, 
    StyledContactTitle, 
    StyledContactWrapper 
} from '../../styled-components/StyledContact';
import { 
    StyledContainer, 
    StyledWrapper 
} from '../../styled-components/StyledCommon';
import { constants } from '../../common/constants';
import MapImg from '../../assets/svgs/new-tara-office-map.svg';
import DotPattern1Img from '../../assets/svgs/dot-pattern-1.svg';
import DotPattern2Img from '../../assets/svgs/dot-pattern-2.svg';

const ContactComponent = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleOnSubmit = () => {
        // TODO: Call Submit API
    }

    const handleFormUpdate = (e) => {
        const data = formData;
        data[e.target.id] = e.target.value;
        setFormData(data);
    }

    return (
        <StyledContainer id='contact'>
            <StyledWrapper>
                <StyledContactWrapper>
                    <StyledContactDetailsWrapper>
                        <StyledContactAddressWrapper>
                            <StyledContactTitle>
                                Contact Us
                            </StyledContactTitle>
                            <StyledContactAddress>
                                <span> {constants.companyName} </span> <br />
                                {constants.address}
                            </StyledContactAddress>
                        </StyledContactAddressWrapper>
                        <MapLocationImg src={MapImg} alt='map-location' width={'100%'} />
                    </StyledContactDetailsWrapper>
                    <StyledContactForm>
                        <StyledContactInput id='fullName' placeholder='Fullname' onChange={handleFormUpdate} />
                        <StyledContactInput id='email' placeholder='Email' onChange={handleFormUpdate} />
                        <StyledContactInput id='phone' placeholder='Phone' onChange={handleFormUpdate} />
                        <StyledContactTextArea id='message' placeholder='Message' onChange={handleFormUpdate} />
                        <StyledContactSubmitBtn onChange={handleOnSubmit}>Submit</StyledContactSubmitBtn>
                    </StyledContactForm>
                <StyledContactBg1 src={DotPattern1Img} alt='bg1' />
                <StyledContactBg2 src={DotPattern2Img} alt='bg2' />
                </StyledContactWrapper>
            </StyledWrapper>
        </StyledContainer>
    )
}

export default ContactComponent;