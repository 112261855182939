import React from 'react';
import HomeComponent from '../components/Home';
import AboutComponent from '../components/About';
import ServiceComponent from '../components/Service';
import MotoComponent from '../components/Moto';
import ContactComponent from '../components/Contact';
import { StyledHomeContainer } from '../styled-components/StyledHome';
import FooterComponent from '../components/Footer';

const Home = () => {
  return (
    <StyledHomeContainer>
      <HomeComponent />
      <MotoComponent />
      <AboutComponent />
      <ServiceComponent />
      <ContactComponent />
      <FooterComponent />
    </StyledHomeContainer>
  )
}

export default Home