import React from 'react'
import { serviceConstants } from '../../common/constants';
import {
    StyledContainer, StyledWrapper,
} from '../../styled-components/StyledCommon';
import { StyledContentsWrapper, StyledServiceDesc, StyledServiceTitle, StyledServiceWrapper, StyledServicesGrid, StyledSubTitleLI, StyledSubTitleOL } from '../../styled-components/StyledService';
import ServiceItem from './ServiceItem';

const ServiceComponent = () => {
    return (
        <StyledContainer id='service'>
            <StyledWrapper>
                <StyledServiceWrapper>
                    <StyledServiceTitle> {serviceConstants.title} </StyledServiceTitle>
                    <StyledContentsWrapper>
                        {/* <StyledSubTitleOL type='A'>
                            {
                                serviceConstants.subTitles.map(subTitle => (<StyledSubTitleLI> {subTitle} </StyledSubTitleLI>))
                            }
                        </StyledSubTitleOL> */}
                        
                        <StyledServicesGrid>
                            {
                                serviceConstants.services.map(service => (<ServiceItem service={service} />))
                            }
                        </StyledServicesGrid>
                        <StyledServiceDesc style={{ fontSize: 18 }}> {serviceConstants.description} </StyledServiceDesc>
                    </StyledContentsWrapper>
                </StyledServiceWrapper>
            </StyledWrapper>
        </StyledContainer>
    )
}

export default ServiceComponent;