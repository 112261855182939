import React from 'react';
import { StyledMotoBg, StyledMotoQuoteIcon, StyledMotoText, StyledMotoWrapper } from '../../styled-components/StyledMoto';
import { StyledContainer, StyledWrapper } from '../../styled-components/StyledCommon';
import QuoteImg from '../../assets/svgs/quote.svg';
import { constants } from '../../common/constants';

const MotoComponent = () => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledMotoWrapper>
          <StyledMotoBg>
            <StyledMotoQuoteIcon src={QuoteImg} alt='quote' />
            <StyledMotoText>{constants.moto}</StyledMotoText>
          </StyledMotoBg>
        </StyledMotoWrapper>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default MotoComponent;