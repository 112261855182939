import { styled } from "styled-components";
import { 
    StyledDiv, 
    StyledFlexCenter, 
    StyledGrid,
    StyledImg, 
    StyledP,
} from "./StyledCommon";

export const StyledAboutWrapper = styled(StyledGrid)`
    grid-template-columns: 50% 50%;
    width: 100%;

    @media screen and (max-width: 1350px) {
        grid-template-columns: 55% 45%;
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: auto;
        padding: 30px 0;
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: auto;
        padding: 0;
    }
`;

export const StyledAboutLeft = styled(StyledFlexCenter)`
    position: relative;
    justify-content: left;
    width: 100%;
    
    @media screen and (max-width: 1200px) {
        justify-content: center;
        justify-self: center;
    }
`;

export const BlobImg = styled(StyledImg)`
    opacity: 20%;
    width: 80%;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
`;

export const AboutImg = styled(StyledImg)`
    position: absolute;
    width: 80%;
    
    @media screen and (max-width: 1400px) {
        width: 80%;
    }
    @media screen and (max-width: 700px) {
        width: 90%;
    }
`;

export const StyledAboutRight = styled(StyledDiv)`
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    justify-self: end;
`;

export const StyledAboutParagraph = styled(StyledP)`
    display: inline-block;
    vertical-align: middle;
    line-height: 2.5;

    @media screen and (max-width: 1620px) {
        line-height: 2;
    }

    @media screen and (max-width: 1200px) {
        line-height: 2.5;
        padding-top: 40px;
    }
`;


export const StyledMessageImg = styled(StyledImg)`
    position: absolute;
    background-color: #FF75CB;
    width: 10%;
    border-radius: 5px;
    padding: 16px;
    top: 5%;
    right: 25%;

    @media screen and (max-width: 1560px) {
        top: 10%;
    }

    @media screen and (max-width: 1350px) {
        top: 15%;
    }

    @media screen and (max-width: 1200px) {
        right: 14%;
        top: 5%;
    }

    @media screen and (max-width: 700px) {
        top: 12%;
    }
`;

export const StyledDiamondImg = styled(StyledImg)`
    position: absolute;
    background-color: #610BEF;
    width: 10%;
    border-radius: 5px;
    padding: 16px;
    bottom: 35%;
    left: 0;

    @media screen and (max-width: 1200px) {
        left: 12%;
    }
    
    @media screen and (max-width: 600px) {
        padding: 10px;
    }
`;