import { styled } from "styled-components";
import {
    StyledBtn,
    StyledDiv,
    StyledFlexColumn,
    StyledGrid,
    StyledImg,
    StyledInput,
    StyledTextArea
} from './StyledCommon';

export const StyledContactWrapper = styled(StyledGrid)`
    flex: 1 1 auto;
    grid-template-columns: 50% 50%;
    justify-content: center;
    gap: 20px;
    position: relative;
    overflot: hidden;

    @media screen and (max-width: 1100px) {
        grid-template-columns: auto;
    } 
`;

export const StyledContactDetailsWrapper = styled(StyledFlexColumn)`
    justify-content: center;
`;

export const StyledContactAddressWrapper = styled(StyledFlexColumn)`
    align-items: left;
`;

export const StyledContactTitle = styled(StyledDiv)`
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: left;
`;

export const StyledContactAddress = styled(StyledDiv)`
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 300px;
    padding-top: 30px;
`;

export const MapLocationImg = styled(StyledImg)`
    max-width: 600px;
    padding-top: 20px;

    @media screen and (max-width: 700) {
        width: 100%;
        align-self: center;
    }
`;

export const StyledContactForm = styled(StyledFlexColumn)`
    justify-content: center;
    align-items: left;
`;

export const StyledContactInput = styled(StyledInput)`
    height: 35px;
    margin: 10px 0;
    border: 1px solid #D1D5DB;
`;

export const StyledContactTextArea = styled(StyledTextArea)`
    flex: 1;
    min-height: 100px;
    max-height: 200px;
    margin: 10px 0;
    border: 1px solid #D1D5DB;
`;

export const StyledContactSubmitBtn = styled(StyledBtn)`
    background-color: #610BEF;
    padding: 16px 30px;
    color: white;
    width: max-content;
    height: max-content;
    margin-top: 10px;
    cursor: pointer;
`;

export const StyledContactBg2 = styled(StyledImg)`
    position: absolute;
    height: 80%;
    width: 30%;
    top: 0;
    right: -200px;
    z-index: -1;

    @media screen and (max-width: 1100px) {
        right: -100px;
        width: 40%;
        top: 350px;
    }
`;

export const StyledContactBg1 = styled(StyledImg)`
    position: absolute;
    height: 80%;
    width: 30%;
    bottom: 0;
    left: -200px;
    z-index: -1;

    @media screen and (max-width: 1100px) {
        left: -100px;
        width: 40%;
        bottom: 450px;
    }
`;
