import { styled } from "styled-components";
import { StyledBtn, StyledFlexCenter, StyledGrid, StyledInput } from "./StyledCommon";
import CoverBanner from '../assets/svgs/cover-banner.svg';
import MobileBanner from '../assets/svgs/banner-mobile.svg';

export const StyledHomeContainer = styled(StyledGrid)`
    gap: 80px;
    padding-top 30px;
    padding-bottom: 60px;

    @media screen and (max-width: 760px) {
        padding-top: 10px;
        gap: 50px;
    }
`;

export const StyledHomeWrapper = styled(StyledFlexCenter)`
    background-image: url(${CoverBanner});
    background-repeat: no-repeat;
    height: 33vw;
    width: 100%;
    background-position: center;
    background-position-y: -1vw;
    background-size: cover;
    border-radius: 10px;

    @media screen and (max-width: 960px) {
        height: 40vw;
        background-position-y: 0vw;
    }

    @media screen and (max-width: 780px) {
        background-position-y: 0;
        background-size: fit;
        height: 400px;
        background-image: url(${MobileBanner});
    }
`;

export const StyledHomeActionArea = styled(StyledFlexCenter)`
    width: 70%;
    max-width: 700px;
    height: max-content;
    margin-top: -5vw;

    @media screen and (max-width: 780px) {
        flex-direction: column;
        width: 90%;
        margin-top: -20px;
    }
`;

export const StyledHomeInput = styled(StyledInput)`
    flex: 1;
    height: 35px;
    margin-right: 30px;
    font-size: 16px;
    width: 80%;

    @media screen and (max-width: 780px) {
        flex-direction: column;
        margin-right: 0;
        padding: 15px 20px;
    }
`;

export const SyledHomeTrackBtn = styled(StyledBtn)`
    background-color: #610BEF;
    color: white;
    cursor: pointer;
    padding: 18px 40px;
    
    @media screen and (max-width: 780px) {
        margin-top: 50px;
        padding: 15px 30px;
    }
`;
