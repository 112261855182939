import React, { useState } from 'react';
import '../../styles/Navbar.scss';
import Logo from '../../assets/svgs/logo.svg';
import Menu from '../../assets/svgs/menu-right.svg';
import { Link } from 'react-scroll';
import { routes } from '../../common/constants';
import { StyledImg } from '../../styled-components/StyledCommon';

const Navbar = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }
    return (
        <div className='nav-container'>
            <div className='wrapper'>
                <img src={Logo} className='logo' alt='logo' />
                <div style={{ cursor: 'pointer' }} onClick={handleMenuClick}>
                    <StyledImg className='nav-mobile-menu' src={Menu} alt='menu-icon' />
                </div>

                <div id="mySidenav" class="sidenav" style={{ width: isMobileMenuOpen === true ? '100%' : '0' }}>
                    <div class="closebtn" onClick={handleMenuClick}>&times;</div>
                    {
                        routes.map(route => (
                            <Link
                                to={route.id}
                                className='nav-link'
                                smooth="true"
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                onClick={handleMenuClick}
                            >
                                {route.label}
                            </Link>
                        ))
                    }
                </div>
                <ul className='nav-menu-container'>
                    {
                        routes.map(route => (
                            <li className='nav-menu'>
                                <Link
                                    to={route.id}
                                    className='nav-scroll-link'
                                    smooth="true"
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                >
                                    {route.label}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Navbar;