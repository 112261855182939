import React from 'react'
import { StyledContainer, StyledImg, StyledWrapper } from '../../styled-components/StyledCommon';
import { StyledFooterSocialWrapper, StyledFooterText, StyledFooterWrapper } from '../../styled-components/Footer';
import Logo from '../../assets/svgs/logo.svg';
import WebLogo from '../../assets/svgs/web.svg';
import FacebookLogo from '../../assets/svgs/facebook.svg';
import TwitterLogo from '../../assets/svgs/twitter.svg';
import LinkenInLogo from '../../assets/svgs/linkedin.svg';
import YoutubeLogo from '../../assets/svgs/youtube.svg';
import { constants } from '../../common/constants';
import { Link } from 'react-scroll';

const FooterComponent = () => {

    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledFooterWrapper>
                    <Link 
                        to={'home'}
                        smooth="true"
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-200}
                    >
                        <StyledImg src={Logo} alt='logo' width={80} height={'auto'}/>
                    </Link>
                    <StyledFooterSocialWrapper>
                        <StyledImg src={WebLogo} alt={'web-logo'} width={40}/>
                        <StyledImg src={FacebookLogo} alt={'web-logo'} width={40}/>
                        <StyledImg src={TwitterLogo} alt={'web-logo'} width={40}/>
                        <StyledImg src={LinkenInLogo} alt={'web-logo'} width={40}/>
                        <StyledImg src={YoutubeLogo} alt={'web-logo'} width={40}/>
                    </StyledFooterSocialWrapper>
                    <StyledFooterText>{ constants.footerText }</StyledFooterText>
                </StyledFooterWrapper>
            </StyledWrapper>
        </StyledContainer>
    )
}

export default FooterComponent;