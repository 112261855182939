import React from 'react'
import { 
    StyledServiceItemContainer, 
    StyledServiceItemDescription, 
    StyledServiceItemImg, 
    StyledServiceItemName, 
    StyledServiceItemIconBg 
} from '../../styled-components/StyledService';
import { StyledFlexColumn } from '../../styled-components/StyledCommon';

const ServiceItem = ({ service }) => {
  return (
    <StyledServiceItemContainer>
        <StyledServiceItemIconBg>
            <StyledServiceItemImg src={service.icon} alt={service.name} />
        </StyledServiceItemIconBg>
        <StyledFlexColumn>
            <StyledServiceItemName>{service.name}</StyledServiceItemName>
            <StyledServiceItemDescription dangerouslySetInnerHTML={{__html: service.description}}></StyledServiceItemDescription>
        </StyledFlexColumn>
    </StyledServiceItemContainer>
  )
}

export default ServiceItem;