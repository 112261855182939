import { styled } from "styled-components";
import { StyledDiv, StyledFlexColumn, StyledImg } from "./StyledCommon";

export const StyledMotoWrapper = styled(StyledFlexColumn)`
    width: 100%;
`;

export const StyledMotoBg = styled(StyledFlexColumn)`
    max-width: 1200px;
    align-self: center;
    border: 2px solid #0563CA;
    border-radius: 30px;
    padding: 50px 30px;

    @media screen and (max-width: 760px) {
        padding: 30px 20px;
    }
`;

export const StyledMotoQuoteIcon = styled(StyledImg)`
    width: 30px;
`;

export const StyledMotoText = styled(StyledDiv)`
    padding: 20px 30px;
    font-size: 30px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;

    @media screen and (max-width: 760px) {
        font-size: 26px;
        padding: 10px;
    }
`;