// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
  background: rgb(169, 150, 255);
  background: linear-gradient(90deg, rgba(166, 153, 225, 0.721) 0%, rgb(255, 255, 255) 50%, rgba(91, 157, 227, 0.721) 100%);
  font-family: "Poppins", sans-serif;
}
.App *:focus {
  outline: none;
}
.App .App-header {
  background-color: #1f3d79;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fb6161;
}
.App .App-header .App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App .App-header .App-link {
  color: #a961fb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/App.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,8BAAA;EACA,yHAAA;EACA,kCAAA;AAAF;AACE;EACE,aAAA;AACJ;AAEE;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,cAAA;AAAJ;AAEI;EACE,cAAA;EACA,oBAAA;AAAN;AAGI;EACE,cAAA;AADN;;AAOA;EACE;IACE,4CAAA;EAJF;AACF;AAOA;EACE;IACE,uBAAA;EALF;EAOA;IACE,yBAAA;EALF;AACF","sourcesContent":["\n.App {\n  width: 100%;\n  background: rgb(169,150,255);\n  background: linear-gradient(90deg, rgba(166, 153, 225, 0.721) 0%, rgb(255, 255, 255) 50%, rgba(91, 157, 227, 0.721) 100%);\n  font-family: 'Poppins', sans-serif;\n  *:focus {\n    outline: none;\n  \n  }\n  .App-header {\n    background-color: #1f3d79;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: #fb6161;\n\n    .App-logo {\n      height: 40vmin;\n      pointer-events: none;\n    }\n\n    .App-link {\n      color: #a961fb;\n    }    \n  }\n}\n\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
